import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'view-design'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    component: () => import('@/views/layout'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/workbench/home'),
        meta: {
          title: '工作台',
        },
      },
      {
        path: '/myWorks',
        name: 'myWorks',
        component: () => import('@/views/workbench/myWorks'),
        meta: {
          title: '我的作品',
        },
      },
      {
        path: '/myInfo',
        name: 'myInfo',
        component: () => import('@/views/workbench/myInfo'),
        meta: {
          title: '个人信息',
        },
      },
      {
        path: '/vipRights',
        name: 'vipRights',
        component: () => import('@/views/workbench/vipRights'),
        meta: {
          title: '会员权益',
        },
      },
      {
        path: '/videoEditor',
        name: 'videoEditor',
        component: () => import('@/views/tools/videoEditor'),
        meta: {
          title: '视频编辑',
        },
      },
      {
        path: '/transVideo',
        name: 'transVideo',
        component: () => import('@/views/tools/transVideo'),
        meta: {
          title: '视频翻译',
        },
      },
      {
        path: '/removeDupe',
        name: 'removeDupe',
        component: () => import('@/views/tools/removeDupe'),
        meta: {
          title: '视频去重',
        },
      },
      {
        path: '/addMusic',
        name: 'addMusic',
        component: () => import('@/views/tools/addMusic'),
        meta: {
          title: '智能配乐',
        },
      },
      {
        path: '/genNovel',
        name: 'genNovel',
        component: () => import('@/views/tools/genNovel'),
        meta: {
          title: '小说生成',
        },
      },
      {
        path: '/text2Video',
        name: 'text2Video',
        component: () => import('@/views/tools/text2Video'),
        meta: {
          title: '文生视频',
        },
      },
      {
        path: '/removeWmk',
        name: 'removeWmk',
        component: () => import('@/views/tools/removeWmk'),
        meta: {
          title: '去水印',
        },
      },
      {
        path: '/removeOpacity',
        name: 'removeOpacity',
        component: () => import('@/views/tools/removeOpacity'),
        meta: {
          title: '去模糊',
        },
      },
      {
        path: '/removeSubtitle',
        name: 'removeSubtitle',
        component: () => import('@/views/tools/removeSubtitle'),
        meta: {
          title: '去字幕',
        },
      },
      {
        path: '/extractSubtitle',
        name: 'extractSubtitle',
        component: () => import('@/views/tools/extractSubtitle'),
        meta: {
          title: '提字幕',
        },
      },
      {
        path: '/removeNoise',
        name: 'removeNoise',
        component: () => import('@/views/tools/removeNoise'),
        meta: {
          title: '去噪音',
        },
      },
      {
        path: '/changeSpeed',
        name: 'changeSpeed',
        component: () => import('@/views/tools/changeSpeed'),
        meta: {
          title: '视频变速',
        },
      },
      {
        path: '/highDefn',
        name: 'highDefn',
        component: () => import('@/views/tools/highDefn'),
        meta: {
          title: '超分辨',
        },
      },
      {
        path: '/imageEditor',
        name: 'imageEditor',
        component: () => import('@/views/tools/imageEditor'),
        meta: {
          title: '图片编辑',
        },
      },
      {
        path: '/success',
        name: 'success',
        component: () => import('@/views/tools/success'),
        meta: {
          title: '提交成功',
        },
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (!localStorage.getItem('token') && to.path != '/home') {
    Message.error('请先登录后再进行操作')
    next('/home')
  } else {
    document.title = `AI影像工坊${to.path == '/home' ? '' : ` - ${to.meta.title}`}`
    next()
  }
})

export default router
