import axios from 'axios'
import { Message } from 'view-design'

const service = axios.create()

service.interceptors.request.use(
  config => {
    config.timeout = 5 * 60 * 1000
    const token = localStorage.getItem('token')
    if (token) config.headers.token = token
    if (config.file) {
      config.headers['Content-Type'] = 'multipart/form-data'
      config.timeout = 0
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.data) {
      const { code } = response.data
      if (code == 200) {
        return Promise.resolve(response.data)
      } else if (code == 20011) {
        localStorage.removeItem('token')
        Message.error(response.data.msg)
        return Promise.reject(response.data.msg)
      } else {
        Message.error(response.data.msg)
        return Promise.reject(response.data.msg)
      }
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
