import request from '@/utils/request.js'

/**
 * ### 前台账号密码登录 username手机号 password密码
 * POST http://localhost:9998/cpapi/login/auth
 */
export function pwdLoginApi(data) {
  return request({
    url: '/cpapi/login/auth',
    method: 'post',
    data: data,
  })
}

/**
 * ### 验证码登录 phone手机 code验证码
 * POST http://localhost:9998/cpapi/login/authbycode
 */
export function codeLoginApi(data) {
  return request({
    url: '/cpapi/login/authbycode',
    method: 'post',
    data: data,
  })
}

/**
 * ### 重置密码 phone手机 code验证码 password新密码
 * POST http://localhost:9998/cpapi/login/repass
 */
export function resetPwdApi(data) {
  return request({
    url: '/cpapi/login/repass',
    method: 'post',
    data: data,
  })
}

/**
 * ### 注册 name姓名,phone手机,code验证码,password密码
 * POST http://localhost:9998/cpapi/login/register
 */
export function registerApi(data) {
  return request({
    url: '/cpapi/login/register',
    method: 'post',
    data: data,
  })
}

/**
 * ### 发送登录验证码 phone
 * POST http://localhost:9998/cpapi/login/loginCaptcha
 */
export function getLoginCodeApi(data) {
  return request({
    url: '/cpapi/login/loginCaptcha',
    method: 'post',
    data: data,
  })
}

/**
 * ### 发送注册验证码 phone
 * POST http://localhost:9998/cpapi/login/registerCaptcha
 */
export function getRegisterCodeApi(data) {
  return request({
    url: '/cpapi/login/registerCaptcha',
    method: 'post',
    data: data,
  })
}

/**
 * ### 发送修改密码验证码
 * POST http://localhost:9998/cpapi/login/repassCaptcha
 */
export function getForgetPwdCodeApi(data) {
  return request({
    url: '/cpapi/login/repassCaptcha',
    method: 'post',
    data: data,
  })
}

/**
 * ### 获取用户信息
 * POST http://localhost:9998/cpapi/login/getinfo
 */
export function getUserInfoApi(data) {
  return request({
    url: '/cpapi/login/getinfo',
    method: 'post',
    data: data,
  })
}

/**
 * ### 获取点卡套餐列表
 * GET http://localhost:9998/api/setmeal/list
 */
export function getPackageListApi(data) {
  return request({
    url: '/api/setmeal/list',
    method: 'get',
    params: data,
  })
}

/**
 * ### 微信支付 setmealId套餐id point点数 price金额
 * http://localhost:9998/api/rechargeOrder/createWx
 * ### 返回 orderId订单号 payUrl二维码链接
 */
export function wxpayApi(data) {
  return request({
    url: '/api/rechargeOrder/createWx',
    method: 'post',
    data: data,
  })
}

/**
 * ### 支付宝支付 setmealId套餐id point点数 price金额
 * POST http://localhost:9998/api/rechargeOrder/createAli
 * ### 返回 orderId订单号 payUrl二维码链接
 */
export function alipayApi(data) {
  return request({
    url: '/api/rechargeOrder/createAli',
    method: 'post',
    data: data,
  })
}

/**
 * ### 查询订单状态 orderId订单号
 * GET http://localhost:9998/api/rechargeOrder/getStatus
 * ### 返回 status状态 1待支付 2已取消 3支付失败 4支付成功
 */
export function getPayStatysApi(data) {
  return request({
    url: '/api/rechargeOrder/getStatus',
    method: 'get',
    params: data,
  })
}

/**
 * ### 查询作品状态
 * GET http://localhost:9998/api/ai/worksStatus
 * ### 返回 status状态 1待处理 2处理中 3成功 4失败
 */
export function getWorkStatusApi(data) {
  return request({
    url: '/api/ai/worksStatus',
    method: 'get',
    params: data,
  })
}
