import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    windowHeight: document.documentElement.clientHeight, // 视窗高度
    // 工作台页面
    workbenchRoutes: [
      { name: '工作台', path: '/home', icon: 'ios-home' },
      { name: '我的作品', path: '/myWorks', icon: 'ios-create' },
      { name: '会员权益', path: '/vipRights', icon: 'ios-ribbon' },
      { name: '个人信息', path: '/myInfo', icon: 'ios-person' },
    ],
    // 推荐工具页面
    recToolsRoutes: [
      { name: '视频翻译', path: '/transVideo?tool=1', sub: '翻译视频语音or翻译视频文字' },
      { name: '文字擦除', path: '/transVideo?tool=2', sub: '清除视频原有文字' },
      { name: '视频去重', path: '/removeDupe', sub: '视频微改动 去重神器' },
      { name: '小说生成', path: '/genNovel', sub: '一键生成or提示生成or灵感续写' },
      { name: '文生视频', path: '/text2Video', sub: '根据描述生成视频或图片' },
    ],
    // 更多工具页面
    moreToolsRoutes: [
      { name: '去水印', path: '/removeWmk', icon: '', sub: '' },
      { name: '去模糊', path: '/removeOpacity', icon: '', sub: '' },
      { name: '去字幕', path: '/removeSubtitle', icon: '', sub: '' },
      { name: '提字幕', path: '/extractSubtitle', icon: '', sub: '' },
      { name: '去噪', path: '/removeNoise', icon: '', sub: '' },
      { name: '视频变速', path: '/changeSpeed', icon: '', sub: '' },
      { name: '超分辨', path: '/highDefn', icon: '', sub: '' },
      { name: '图片编辑', path: '/imageEditor', icon: '', sub: 'AI抠图/AI擦除' },
      { name: '智能配乐', path: '/addMusic', icon: '', sub: '' },
    ],
    currentPath: '', // 当前所在页面path
    username: '', // 用户昵称
    userPoint: 0, // 用户点卡余额
  },
  getters: {},
  mutations: {
    updateWindowHeight(state, value) {
      state.windowHeight = value
    },

    updateCurrentPath(state, value) {
      state.currentPath = value
    },

    updateUsername(state, value) {
      state.username = value
    },

    updateUserPoint(state, value) {
      state.userPoint = value
    },
  },
  actions: {},
  modules: {},
})
